import * as React from 'react'

import Components from '../components/Components'
import { StoryblokBase } from '../types'

interface StoryblokEntryProps {
  pageContext: {
    story: StoryblokBase,
    global: StoryblokBase
  };
  location: Location;
}

class StoryblokEntry extends React.Component<StoryblokEntryProps, { story: StoryblokBase, global: StoryblokBase }> {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    const global = Object.assign({}, props.pageContext.global)
    story.content = JSON.parse(story.content)
    global.content = JSON.parse(global.content)

    return { story, global }
  }

  constructor(props) {
    super(props)
    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    let content = this.state.story.content
    let global = this.state.global.content
    return (
      <div>
        {React.createElement(Components(content.component), { key: content._uid, blok: content, global: global, location: this.props.location })}
      </div>
    )
  }
}

export default StoryblokEntry
